import React from 'react';
import './Icon.css';
import { services } from '../constants';

interface Props {
  type: string;
  title: string;
}

const Icon: React.FC<Props> = (props) => {
  const { type, title } = props;

  const service = services.find(service => service.type === type);
  return (
    <span className={`Icon ${type}`}>
      <p className="hiddenText">
        {`Play ${title} on ${service ? service.name : 'this streaming service'}`}
      </p>
    </span>
  );
};

export default Icon;
