import React from 'react';
import { getYouTubeID } from '../library';
import Icon from './Icon';
import './Video.css';
import defaultVideo from '../assets/default_youtube_thumbnail_320x180.gif';

interface Props {
  src: string;
  title: string;
  onClick?: any;
}

const Video: React.FC<Props> = (props) => {
  const { src, title, onClick } = props;
  const ytID = getYouTubeID(src);
  return (
    <a className="video youtube" href={src} rel="noopener noreferrer" target="_blank" onClick={onClick}>
      <img className="thumbnail" src={src !== '' ? `https://img.youtube.com/vi/${ytID}/mqdefault.jpg` : defaultVideo} alt={title} onClick={onClick} />
      <div className="youtube play" />
      <Icon type="youtube" title={title} />
    </a>
  );
};

export default Video;
