import React from 'react';
import { connect } from 'react-redux';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import {
  faInstagram,
  faFacebook,
  faYoutube,
  faTwitter,
  faItunes,
  faSoundcloud,
  faSpotify,
  faApple,
} from '@fortawesome/free-brands-svg-icons';
import { faParking } from '@fortawesome/free-solid-svg-icons';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import EditIcon from '@material-ui/icons/Edit';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Route, Link as RouterLink } from 'react-router-dom';
import { Location } from 'history';
import { Dispatch } from 'redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Link } from './constants';
import { AppState } from './store';
import PrettyButton from './components/PrettyButton';
import Spotlight from './containers/Spotlight';
import Form from './containers/Form';
import defaultBackgroundImage from './assets/default_song_artwork_1000x1000.gif';
import { toggleEditor, toggleUser } from './actions';
import ReactPixel from 'react-facebook-pixel';

library.add(
  faInstagram,
  faFacebook,
  faYoutube,
  faTwitter,
  faItunes,
  faSpotify,
  faSoundcloud,
  faApple,
  faParking,
);

interface Props extends AppState {
  location: Location;
  dispatchToggleEditor: () => void;
  dispatchToggleUser: () => void;
}

interface State {
  anchorEl: null | HTMLElement;
}

export class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  componentDidMount() {
    const {
      song: { title },
    } = this.props;
    document.title = `${title} Smartlinks`;

    this.renderBackgroundImage(this.props.song.artwork);

    const path = this.props.location.pathname.match(/(\/edit)(\/)?\d?/g);
    if (path !== null && this.props.system.editor === false) {
      this.toggleEditor();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.song.artwork !== prevProps.song.artwork) {
      this.renderBackgroundImage(this.props.song.artwork);
    }
  }

  toggleEditor = () => {
    this.props.dispatchToggleEditor();
  };

  handleOverflow = (el: HTMLElement) => {
    this.setState({
      anchorEl: el,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  toggleUser = (loggedIn: boolean) => {
    if (loggedIn === true && this.props.system.editor === true) {
      this.toggleEditor();
    }
    this.handleClose();
    this.props.dispatchToggleUser();
  };

  renderBackgroundImage = (artwork: string) => {
    const root = document.getElementById('root')!;
    const canvasNode = document.getElementById('canvas') as HTMLCanvasElement;

    if (canvasNode === null) {
      const canvas = document.createElement('canvas');
      canvas.width = 1000;
      canvas.height = 1000;
      canvas.id = 'canvas';
      root.prepend(canvas);
      this.drawCanvas(canvas, artwork);
    } else { 
      this.drawCanvas(canvasNode, artwork);
    }
  };

  drawCanvas = (canvas: HTMLCanvasElement, artwork: string) => {
    const ctx = canvas.getContext('2d')!;
    ctx.filter = 'blur(20px)';
    const image = new Image();
    image.src = artwork || defaultBackgroundImage;
    image.onload = () => {
      ctx.drawImage(image, 0, 0);
      canvas.className = 'fade-in';
    };
  };


  renderPrettyButtons = (title: string, links: Link[]) => {
    const listButtons = links.map((item) => {
      if (item.type === 'youtube' || item.url === '') {
        return null;
      }
      return <PrettyButton key={item.id} type={item.type} url={item.url} title={title} onClick={() => ReactPixel.track('ViewContent', {
        content_name: `Media - ${item.type}`
        })}/>;
    });

    return <section className="links">{listButtons}</section>;
  };

  render() {
    const {
      song: { title, spotlight, links },
      artist: { name, sites },
      system: { editor, loggedIn },
    } = this.props;
    const { anchorEl } = this.state;
    const website = sites.find(item => item.type === 'website');
    const socials = sites.filter(item => item.url !== '' && item.type !== 'website');
    return (
      <div className="App">
        <CssBaseline />
        {loggedIn && (
          <div className={`editor-menu dark ${editor === true ? 'open' : 'closed'}`}>
            <div className="drawer-header">
              <Typography variant="h5">Editor</Typography>
              <RouterLink to="/">
                <IconButton onClick={() => this.toggleEditor()}>
                  <ChevronLeftIcon />
                </IconButton>
              </RouterLink>
            </div>
            <Divider />
            <Route path="/edit/:step?" component={Form} />
          </div>
        )}
        <div className={`main ${editor === true ? 'open' : 'closed'}`}>
          {loggedIn && (
            <RouterLink
              to="/edit/1"
              className={`editor-button menu light ${editor === true ? 'open' : 'closed'}`}
              onClick={() => this.toggleEditor()}
            >
              <IconButton aria-label="Menu">
                <EditIcon />
              </IconButton>
            </RouterLink>
          )}
          <IconButton
            className="overflow-menu"
            aria-label="overflow-menu"
            aria-haspopup="true"
            onClick={e => this.handleOverflow(e.currentTarget)}
          >
            <MoreVertIcon />
          </IconButton>
       {/*    <Menu
            id="overflow-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => this.handleClose()}
          >
            <MenuItem
              onClick={() => {
                this.toggleUser(loggedIn);
              }}
            >
              <RouterLink to="/" className="light" style={{textDecoration: 'none'}}>{loggedIn === true ? 'Logout' : 'Login'}</RouterLink>
            </MenuItem>
          </Menu> */}
          <div className="container">
            <header>
              {/* <img className="artwork small" src={artwork} alt={`${title} by ${name}`} /> */}
              <div>
                <h1>{title || 'Song Title'}</h1>
                <h3>{name || 'Artist Name'}</h3>
              </div>
            </header>
            <main>
              <Spotlight type={spotlight} song={this.props.song} />
              {this.renderPrettyButtons(title, links)}
            </main>
            <footer className="light">
              {socials.length > 0 && (
                <React.Fragment>
                  <h5 className="uppercase">{`Follow ${name}`}</h5>
                  <p>
                    {socials.map((item, index) => {
                      let icon: IconDefinition;
                      switch (item.type) {
                        case 'facebook':
                          icon = faFacebook;
                          break;
                        case 'instagram':
                          icon = faInstagram;
                          break;
                        case 'twitter':
                          icon = faTwitter;
                          break;
                        case 'youtube':
                          icon = faYoutube;
                          break;
                        default:
                          return false;
                      }
                      return (
                        <a key={index} href={item.url} className={`${item.type} social light`} target="blank" onClick={() => ReactPixel.track('ViewContent', {
                          content_name: `Social - ${item.type}`
                          })}>
                          <FontAwesomeIcon icon={icon} size="2x" />
                        </a>
                      );
                    })}
                  </p>
                </React.Fragment>
              )}
              <p>
                &copy;
                {` ${new Date().getFullYear()} `}
                <a className="light" href={website ? website.url : '/'} target="blank" onClick={() => ReactPixel.track('ViewContent', {
        content_name: `Website - ${website ? website.url : 'Smartlinks'}`
        })}>
                  {website ? website.url : 'Smartlinks'}
                </a>
              </p>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => state;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchToggleEditor: () => dispatch(toggleEditor()),
  dispatchToggleUser: () => dispatch(toggleUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
