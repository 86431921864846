export interface Site {
  type: string;
  url: string;
}

export interface Link extends Site {
  id: string;
}

export interface Song {
  id: string;
  slug: string;
  title: string;
  artist: string;
  artwork: string;
  spotlight: string;
  preview: string;
  links: Link[];
}
export interface Artist {
  id: string;
  name: string;
  sites: Site[];
}

export interface System {
  theme: string;
  editor: boolean;
  loggedIn: boolean;
}

export const UPDATE_SONG_INFO = 'UPDATE_SONG_INFO';

export const CREATE_SONG_LINK = 'CREATE_SONG_LINK';
export const UPDATE_SONG_LINK = 'UPDATE_SONG_LINK';
export const DELETE_SONG_LINK = 'DELETE_SONG_LINK';

// TODO Create Artist
export const UPDATE_ARTIST_INFO = 'UPDATE_ARTIST_INFO';
// TODO Delete Artist

// TODO Create artist link
export const UPDATE_ARTIST_SITE = 'UPDATE_ARTIST_SITE';
// TODO Delete artist link

// TODO Spotify Preview link

// TODO update image

export const TOGGLE_EDITOR = 'TOGGLE_EDITOR';
export const TOGGLE_USER = 'TOGGLE_USER';

interface updateMeta {
  type: typeof UPDATE_SONG_INFO;
  payload: {
    [x: string]: string;
  };
}

interface createLink {
  type: typeof CREATE_SONG_LINK;
  payload: Link;
}

interface updateLink {
  type: typeof UPDATE_SONG_LINK;
  id: string;
  payload: {
    [x: string]: string;
  };
}

interface deleteLink {
  type: typeof DELETE_SONG_LINK;
  payload: Link;
}

interface updateArtist {
  type: typeof UPDATE_ARTIST_INFO;
  payload: {
    [x: string]: string;
  };
}

interface updateSite {
  type: typeof UPDATE_ARTIST_SITE;
  slug: string;
  payload: string;
}

interface toggleEditor {
  type: typeof TOGGLE_EDITOR;
}

interface toggleUser {
  type: typeof TOGGLE_USER;
}

export type SongActionTypes = updateMeta | createLink | updateLink | deleteLink;

export type ArtistActionTypes = updateArtist | updateSite;

export type SystemActionTypes = toggleEditor | toggleUser;

export const services = [
  {
    type: 'youtube',
    name: 'YouTube',
    prefix: 'fab',
    icon: 'youtube',
  },
  {
    type: 'spotify',
    name: 'Spotify',
    prefix: 'fab',
    icon: 'spotify',
  },
  {
    type: 'apple-music',
    name: 'Apple Music',
    prefix: 'fab',
    icon: 'apple',
  },
  {
    type: 'itunes',
    name: 'iTunes',
    prefix: 'fab',
    icon: 'itunes',
  },
  {
    type: 'soundcloud',
    name: 'Soundcloud',
    prefix: 'fab',
    icon: 'soundcloud',
  },
  {
    type: 'pandora',
    name: 'Pandora',
    prefix: 'fas',
    icon: 'parking',
  },
];

export const socials = [
  {
    type: 'facebook',
    name: 'Facebook',
  },  {
    type: 'instagram',
    name: 'Instagram',
  },  {
    type: 'twitter',
    name: 'Twitter',
  },  {
    type: 'youtube',
    name: 'YouTube',
  },
  {
    type: 'website',
    name: 'Artist Website'
  }
];