import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import './Form.css';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
// import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
// import AddIcon from '@material-ui/icons/Add';
// import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { Link as RouterLink } from 'react-router-dom';
// import IconButton from '@material-ui/core/IconButton';
// import Fab from '@material-ui/core/Fab';
// import Tooltip from '@material-ui/core/Tooltip';
// import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogActions from '@material-ui/core/DialogActions';
// import { makeID } from '../library';
import defaultArtwork from '../assets/default_song_artwork_1000x1000.gif';
import {
  updateSong,
  createLink,
  updateLink,
  deleteLink,
  updateArtist,
  updateSite,
  toggleEditor,
} from '../actions';
import {
  Song, Artist, services, socials, Link,
} from '../constants';
import { AppState } from '../store';

interface Props extends AppState {
  match: { params: { step: string } };
  dispatchUpdateSong: (pair: { [x: string]: string }) => void;
  dispatchUpdateArtist: (pair: { [x: string]: string }) => void;
  dispatchCreateLink: (link: Link) => void;
  dispatchUpdateLink: (id: string, data: { [x: string]: string }) => void;
  dispatchDeleteLink: (link: Link) => void;
  dispatchUpdateSite: (slug: string, url: string) => void;
  dispatchToggleEditor: () => void;
}

interface State {
  activeStep: number;
}

export class Form extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeStep: this.props.match.params.step ? parseInt(this.props.match.params.step) - 1 : 0,
    };
  }

  handleNext = () => {
    this.setState(prevState => ({ activeStep: prevState.activeStep + 1 }));
  };

  handleBack = () => {
    this.setState(prevState => ({ activeStep: prevState.activeStep - 1 }));
  };

  handleSubmit = () => {
    this.props.dispatchToggleEditor();
  };

  // TODO Validate song
  handleUpdateSong = (key: keyof Song, value: string) => {
    const pair = { [key]: value };
    this.props.dispatchUpdateSong(pair);
  };

  // TODO Create Link
  // handleCreateLink = () => {
  //   const id = makeID(11);
  //   const link = {
  //     id,
  //     type: 'youtube',
  //     url: 'http://youtube.com/',
  //   };
  //   this.props.dispatchCreateLink(link);
  // };

  handleUpdateLink = (id: string, key: keyof Link, value: string) => {
    // TODO validate Value
    const data = {
      [key]: value,
    };
    this.props.dispatchUpdateLink(id, data);
  };

  handleDeleteLink = (link: Link) => {
    this.props.dispatchDeleteLink(link);
  };

  // TODO Create Artist
  handleUpdateArtist = (key: keyof Artist, value: string) => {
    // TODO Validate VALUE
    const pair = { [key]: value };
    this.props.dispatchUpdateArtist(pair);
  };
  // TODO Delete Artist

  handleUpdateSite = (type: string, url: string) => {
    // TODO VALIDATE URL HERE
    this.props.dispatchUpdateSite(type, url);
  };

  // TODO upload image ?

  // handleCloseModal = () => {
  //   this.setState({ openModal: false, currentLink: '' });
  // };

  // handleOpenModal = (link: Link) => {
  //   this.setState({ openModal: true, currentLink: link });
  // };

  // handleModalAgree = () => {
  //   this.handleDeleteLink(this.state.currentLink as Link);
  //   this.handleCloseModal();
  // };

  render() {
    const { activeStep } = this.state;
    const {
      song: {
        title, artwork, preview, links, spotlight,
      },
      artist: { name, sites },
    } = this.props;
    const youtube = links.find(item => item.type === 'youtube');
    const page = activeStep + 1;
    return (
      <React.Fragment>
        <form className="song-form" onSubmit={() => this.handleSubmit()}>
          {activeStep === 0 && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5" align="center">
                  Song Information
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="song-title-input"
                  label="Song Title"
                  value={title}
                  onChange={e => this.handleUpdateSong('title', e.target.value)}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="artist-name-input"
                  label="Artist Name"
                  value={name}
                  onChange={e => this.handleUpdateArtist('name', e.target.value)}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <img src={artwork || defaultArtwork} alt="Artwork Preview" />
              </Grid>
              <Grid item xs={12} sm={8} style={{alignSelf: 'center'}}>
                <TextField
                  id="artwork-url-input"
                  label="Album Art"
                  value={artwork}
                  placeholder="http://www.yourwebsite.com/img.jpg"
                  helperText="Enter a link to your album art."
                  onChange={e => this.handleUpdateSong('artwork', e.target.value)}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id={`${youtube ? youtube.id : 'youtube'}-link-url-input`}
                  label="Music Video"
                  value={youtube ? youtube.url : ''}
                  fullWidth
                  placeholder="http://www.youtube.com/watch?v=12345678901"
                  helperText="Enter a youtube link of your music video."
                  onChange={e => this.handleUpdateLink(youtube ? youtube.id : 'ID', 'url', e.target.value)
                  }
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="preview-url-input"
                  label="Song Preview"
                  value={preview}
                  placeholder="http://www.yourwebsite.com/song.mp3"
                  helperText="Enter a link to an mp3 preview of your music."
                  onChange={e => this.handleUpdateSong('preview', e.target.value)}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={5} style={{ alignSelf: 'center', textAlign: 'left', textIndent: 12 }}>
                Spotlight:
              </Grid>
              <Grid item xs={3} style={{ alignSelf: 'center', textAlign: 'right' }}>
                Album Art
              </Grid>
              <Grid item xs={2}>
                <Switch
                  checked={spotlight !== 'artwork'}
                  value="spotlight"
                  color="default"
                  onChange={e => this.handleUpdateSong(
                    'spotlight',
                    e.target.checked === false ? 'artwork' : 'video',
                  )
                  }
                  inputProps={{ 'aria-label': 'Spotlight Checkbox' }}
                />
              </Grid>
              <Grid item xs={2} style={{ alignSelf: 'center', textAlign: 'left' }}>
                Video
              </Grid>
              <Grid item xs={12} style={{ textIndent: 12 }}>
                <FormHelperText id="my-helper-text">
                  Select which content type you would like to spotlight.
                </FormHelperText>
              </Grid>
            </Grid>
          )}
          {activeStep === 1 && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5" align="center" gutterBottom>
                  Streaming Links
                </Typography>
              </Grid>
              {links.length !== 0
                && links.map((link, i) => {
                  const service = services.find(item => item.type === link.type)!;
                  return (
                    <React.Fragment key={i}>
                      {/* <Grid item xs={12} sm={4}>
                        <TextField
                          id={`${link.id}-link-type-select`}
                          select
                          fullWidth
                          label="Link Type"
                          value={link.type}
                          onChange={e => this.handleUpdateLink(link.id, 'type', e.target.value)}
                          margin="dense"
                          variant="outlined"
                        >
                          {services.map(option => (
                            <MenuItem key={option.type} value={option.type}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid> */}
                      <Grid item xs={1} style={{ alignSelf: 'center' }}>
                          <FontAwesomeIcon icon={[service.prefix as IconPrefix, service.icon as IconName]} size="2x" />
                      </Grid>
                      <Grid item xs={11}>
                        <TextField
                          id={`${link.id}-link-url-input`}
                          label={`${service.name} Url`}
                          value={link.url}
                          fullWidth
                          onChange={e => this.handleUpdateLink(link.id, 'url', e.target.value)}
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                      {/* <Grid item xs={1}>
                        <Tooltip title="Delete" aria-label="Delete">
                          <IconButton
                            aria-label="Delete"
                            onClick={() => this.handleOpenModal(link)}
                          >
                    <DeleteIcon />
                  </IconButton>
                        </Tooltip>
                </Grid> */}
                    </React.Fragment>
                  );
                })}
              {/* <Dialog
                open={openModal}
                onClose={() => this.handleCloseModal()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Delete this link?</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this link? This action is not undoable.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => this.handleCloseModal()} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={() => this.handleModalAgree()} color="primary" autoFocus>
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
              <Tooltip title="Add" aria-label="Add">
                <Fab
                  color="primary"
                  aria-label="Add"
                  disabled={links.length === services.length ? true : false}
                  style={{ position: 'absolute', bottom: '16px', right: '16px' }}
                  onClick={() => this.handleCreateLink()}
                >
                  <AddIcon />
                </Fab>
              </Tooltip> */}
            </Grid>
          )}

          {activeStep === 2 && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5" align="center" gutterBottom>
                  Artist Information
                </Typography>
              </Grid>
              {sites.length !== 0
                && sites.map((site, i) => {
                  const social = socials.find(item => item.type === site.type);
                  return (
                    <React.Fragment key={i}>
                      <Grid item xs={1} style={{ alignSelf: 'center' }}>
                        {site.type !== 'website' && (
                          <FontAwesomeIcon icon={['fab', site.type as IconName]} size="2x" />
                        )}
                      </Grid>
                      <Grid item key={i} xs={11}>
                        <TextField
                          id={`${site.type}-url-input`}
                          label={`${social ? social.name : 'Social Media'}${site.type !== 'website' ? ' Profile' : ''}`}
                          value={site.url}
                          fullWidth
                          onChange={e => this.handleUpdateSite(site.type, e.target.value)}
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                    </React.Fragment>
                  );
                })}
            </Grid>
          )}
        </form>
        <MobileStepper
          variant="dots"
          steps={3}
          position="static"
          className="stepper"
          activeStep={activeStep}
          nextButton={(
            <RouterLink to={activeStep === 2 ? '/' : `/edit/${page + 1}`} className="active-link">
              <Button
                size="small"
                onClick={activeStep === 2 ? () => this.handleSubmit() : () => this.handleNext()}
              >
                {activeStep === 2 ? 'Done' : 'Next'}
                <KeyboardArrowRight />
              </Button>
            </RouterLink>
)}
          backButton={(
            <RouterLink
              to={`/edit/${page - 1}`}
              className={activeStep === 0 ? 'disabled-link' : 'active-link'}
            >
              <Button size="small" onClick={() => this.handleBack()} disabled={activeStep === 0}>
                <KeyboardArrowLeft />
                Back
              </Button>
            </RouterLink>
)}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => state;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchUpdateSong: (pair: { [x: string]: string }) => dispatch(updateSong(pair)),
  dispatchUpdateArtist: (pair: { [x: string]: string }) => dispatch(updateArtist(pair)),
  dispatchCreateLink: (link: Link) => dispatch(createLink(link)),
  dispatchUpdateLink: (id: string, data: { [x: string]: string }) => dispatch(updateLink(id, data)),
  dispatchDeleteLink: (link: Link) => dispatch(deleteLink(link)),
  dispatchUpdateSite: (slug: string, url: string) => dispatch(updateSite(slug, url)),
  dispatchToggleEditor: () => dispatch(toggleEditor()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Form);
