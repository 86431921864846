import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import { BrowserRouter, Route } from 'react-router-dom';
import configureStore from './store';
import * as serviceWorker from './serviceWorker';
import ReactPixel, { AdvancedMatching, Options } from 'react-facebook-pixel';
import App from './App';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
// import CssBaseline from '@material-ui/core/CssBaseline';

const store = configureStore();

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

// setp facebook pixel
const advancedMatching = { em: 'bbrooks@yoursondemand.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
    autoConfig: false, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};

ReactPixel.init('1065492747259603', advancedMatching as AdvancedMatching, options as Options);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Route path="/" component={App} />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// FUTURE APP IDEAS
// TODO Connect to Express/MongoDB
// TODO Banner Sync Feautre
// TODO Batch Lyrics
// TODO Syndicate Content
// TODO Smartlinks Business Card
// TODO Rebrand to Record Label Smart Tools

// CURRENT WORK
// TODO Add link to CHANGELOG.md to README.md
// TODO Write README.md
// TODO Dynamic Facebook Pixel
// TODO Artwork Spolight Play Button
// TODO jest Spy Class Methods
// TODO Figure out proper home for these todos
// TODO deprecate Link interface and id
// TODO EDITOR: Format ID when full url is the input
// TODO EDITOR: static services show domain
// TODO Cookie Retail prefer and cookie permissions notice with edit

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
