import React from 'react';
import './PrettyButton.css';
import Icon from './Icon';

interface Props {
  type: string;
  url: string;
  key: string;
  title: string;
  onClick?: any;
}

const PrettyButton: React.FC<Props> = (props) => {
  const { type, url, title, onClick } = props;

  return ( 
    <a className={`pretty-button ${type}`} href={url} rel="noopener noreferrer" target="_blank" onClick={onClick}>
      {props.children}
      <Icon type={type} title={title} />
    </a>
  );
};

export default PrettyButton;
