import {
  UPDATE_SONG_INFO,
  Link,
  UPDATE_SONG_LINK,
  DELETE_SONG_LINK,
  CREATE_SONG_LINK,
  UPDATE_ARTIST_INFO,
  UPDATE_ARTIST_SITE,
  TOGGLE_EDITOR,
  TOGGLE_USER,
} from '../constants';

export function updateSong(data: { [x: string]: string }) {
  return { type: UPDATE_SONG_INFO, payload: data };
}

export function createLink(link: Link) {
  return { type: CREATE_SONG_LINK, payload: link };
}

export function updateLink(id: string, data: { [x: string]: string }) {
  return { type: UPDATE_SONG_LINK, id, payload: data };
}

export function deleteLink(link: Link) {
  return { type: DELETE_SONG_LINK, payload: link };
}

export function updateArtist(data: { [x: string]: string }) {
  return { type: UPDATE_ARTIST_INFO, payload: data };
}

export function updateSite(slug: string, url: string) {
  return { type: UPDATE_ARTIST_SITE, slug, payload: url };
}

export function toggleEditor() {
  return { type: TOGGLE_EDITOR }
}

export function toggleUser() {
  return { type: TOGGLE_USER }
}