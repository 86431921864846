import {
  Song,
  Artist,
  System,
  UPDATE_SONG_INFO,
  UPDATE_SONG_LINK,
  SongActionTypes,
  DELETE_SONG_LINK,
  CREATE_SONG_LINK,
  ArtistActionTypes,
  UPDATE_ARTIST_INFO,
  UPDATE_ARTIST_SITE,
  SystemActionTypes,
  TOGGLE_EDITOR,
  TOGGLE_USER,
} from '../constants';
import { song, artist, system } from '../SampleData.json';

export function songReducer(state = song, action: SongActionTypes): Song {
  switch (action.type) {
    case UPDATE_SONG_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case CREATE_SONG_LINK:
      return {
        ...state,
        links: [...state.links, action.payload],
      };
    case UPDATE_SONG_LINK:
      const updateLinks = state.links.map(item => (item.id === action.id
        ? {
          ...item,
          ...action.payload,
        }
        : item));
      return {
        ...state,
        links: updateLinks,
      };
    case DELETE_SONG_LINK:
      const filterLinks = state.links.filter(item => item.id !== action.payload.id);
      return {
        ...state,
        links: filterLinks,
      };

    // TODO Spotify Preview link

    default:
      return state;
  }
}

export function artistReducer(state = artist, action: ArtistActionTypes): Artist {
  switch (action.type) {
    case UPDATE_ARTIST_INFO:
      return { ...state, ...action.payload };
    // TODO Create Artist
    // TODO Update Artist
    // TODO Delete Artist

    // TODO Create artist link
    // TODO Update artist link
    // TODO Delete artist link
    case UPDATE_ARTIST_SITE:
      const updateSites = state.sites.map(item => (item.type === action.slug
        ? {
          ...item,
          url: action.payload,
        }
        : item));
      return {
        ...state,
        sites: updateSites,
      };    // TODO Delete artist link
    default:
      return state;
  }
}

export function systemReducer(state = system, action: SystemActionTypes): System {
  switch (action.type) {
    case TOGGLE_EDITOR:
      return { ...state, editor: !state.editor }
    case TOGGLE_USER:
      return {...state, loggedIn: !state.loggedIn }  
    default:
      return state;
  }
}
