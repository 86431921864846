import React from 'react';
import { Song } from '../constants';
import Artwork from '../components/Artwork';
import defaultArtwork from '../assets/default_song_artwork_1000x1000.gif';
import Video from '../components/Video';
import ReactPixel from 'react-facebook-pixel';

interface Props {
  type: string;
  song: Song;
}

class Spotlight extends React.Component<Props> {
  renderSpotlight = (type: string) => {
    const {
      song: {
        title, artwork, links, preview,
      },
    } = this.props;

    if (type === 'video') {
      const link = links.find(i => i.type === 'youtube');
      if (link !== undefined) {
        return <Video title={title} src={link.url} onClick={() => ReactPixel.track('ViewContent', {
          content_name: `Video - ${title}`
          })} />;
      }
    }
    return (
      <React.Fragment>
        <Artwork title={title} src={artwork || defaultArtwork} />
        {preview ? (
          <React.Fragment>
            <h4 className="light uppercase">Preview Track</h4>
            <audio controls>
              <source src={preview} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  };

  render() {
    const { type } = this.props;
    return <section className="spotlight">{this.renderSpotlight(type)}</section>;
  }
}

export default Spotlight;
