import React from 'react';
import './Artwork.css';

interface Props {
  title: string;
  src: string;
}

const Artwork: React.FC<Props> = (props) => {
  const { src, title } = props;
  return <img className="artwork large" src={src} alt={`${title} Album Art`} />;
};

export default Artwork;
